import React, { useState } from 'react';
import { toast } from 'react-toastify';
import '../App.css';

function TileForm({index, data, setData}) {
    const [title, setTitle] = useState(data[index].Title);
    const [date, setDate] = useState(data[index].Date);
    const [isRecurring, setIsRecurring] = useState(!!data[index]?.IsRecurring);
    const [isEmpty, setIsEmpty] = useState(!!data[index]?.IsEmpty);

    const handleSubmit = () => {
        let ele = {Title: title, Date: date, IsEmpty: false, IsRecurring: isRecurring};
        let modifiedData = [...data];
        modifiedData[index] = ele;
        setIsEmpty(false);
        setData(modifiedData);
        toast.success('Tile Saved!');
    };

    const handleRemove = () => {
        setIsEmpty(true);
        let ele = {IsEmpty: isEmpty, Title: '', Date: '', IsRecurring: false};
        let modifiedData = [...data];
        modifiedData[index] = ele;
        setData(modifiedData);
        toast.success('Tile Removed!');
    };

    return (
        <div className= 'tile'>   
            <label style={{ 'marginTop': '50px' }}> Time till </label>
            <input
                type="text"
                value={title}
                placeholder={title}
                onChange={e => setTitle(e.target.value)}
                required
                style={{ padding: '10px', fontSize: '16px', marginTop: '3rem', marginLeft:'10px'}}
            />
            <br />
            <label style={{ gap: '10px' }}>
                Date:
                <input
                    type="datetime-local"
                    value={date}
                    onChange={e => setDate(e.target.value)}
                    required
                    style={{ padding: '10px', fontSize: '16px', marginTop: '2rem', marginLeft:'10px'}}
                />
            </label>
            <br />
            <label style={{ gap: '10px' }}> 
                Is Recurring:
                <input type="checkbox" checked={isRecurring} onChange={e => setIsRecurring(e.target.checked)} style={{marginTop: '2rem', marginLeft:'10px'}} />
            </label>
            <div style={{marginTop: '2rem'}}>
                <button type="submit" onClick={handleSubmit} className='btn'>Save Tile</button>
                <button type="submit" onClick={handleRemove} disabled={isEmpty} className='btn'>Remove Tile</button>
            </div>
        </div>
    );
}

// style={{ display: 'flex', flexDirection: 'column' }}>
export default TileForm;