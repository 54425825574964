import { Tile } from "./tile";
import '../App.css';
import { InsertData } from "../Data/GetData";
import { toast } from "react-toastify";

const TileColumn = (row1, row2, row3, edit, data, setData) => {
    return (
        <header className="App-header">
            {Tile(data, setData, row1, edit)}
            {Tile(data, setData, row2, edit)}
            {Tile(data, setData, row3, edit)}
        </header> 
    );
};

const createDashboard = async (id, setData) => {
    try{
        const { tiles } = await InsertData(id);
        setData(tiles);
        toast.success('Dashboard created successfully!');
    } catch (error){
        console.error(error);
        toast.error('Could not create Dashboard!');
    }
}

const TileArea = ({id, data, setData, edit}) => {
    if(data.length === 0) {
        return (
            <header className="App-header-row">
                <h1> Create a Dashboard </h1>
                <br/>
                <button type="submit" onClick={()=>createDashboard(id, setData)} className='btn'>Create</button>
            </header>
        );
    }

    return (
        <header className="App-header-row">
            {TileColumn(0,1,2, edit, data, setData)}
            {TileColumn(3,4,5, edit, data, setData)}
            {TileColumn(6,7,8, edit, data, setData)}
        </header>
    );
}
export { TileArea };