import { useState, useEffect } from 'react';
import { timeRemaining } from '../Utils/TimeCalc';
import './tile.css';
import { CountdownTile, EmptyTile, ElapsedTile } from './TileTemplates';
import TileForm from './TileForm';

const Tile = (data, setData, index, edit) => {
    let ele = data[index];
    let eventDate = new Date(ele.Date) ;
    let title = ele.Title;
    let isEmpty = ele.IsEmpty;
    let isRecurring = ele.IsRecurring;
    
    const diff = () => {
        if(isRecurring){
            if(eventDate-new Date() < 0 ){
                let today = new Date();
                while(eventDate-today<0){
                    eventDate.setFullYear(eventDate.getFullYear()+1);
                }
                isRecurring = false;
            }
        }
        return Math.floor((eventDate - new Date()) / 1000);
    };
    const [countdown, setCountdown] = useState(diff());
    useEffect(() => {
        const updateCountdown = () => {
            setCountdown(diff());
        };

        // Call once immediately, then set up the interval.
        updateCountdown();
        const intervalId = setInterval(updateCountdown, 1000);

        // Return a cleanup function that will be called when the component unmounts.
        return () => {
            clearInterval(intervalId);
        };

        // eslint-disable-next-line
    }, [eventDate]); // Only re-run the effect if eventDate changes

    const [ seconds, minutes, hours, days ] = timeRemaining(countdown);
    let isNear = days < 1;
    let isElapsed = minutes < 0 && seconds <= 0;
    
    if(isEmpty) {
        return edit? <TileForm index={index} data={data} setData={setData}  /> :<EmptyTile />;
    } else if(isElapsed) {
        return edit? <TileForm index={index} data={data} setData={setData}  /> :<ElapsedTile title={title} />;
    } else {
        return edit? <TileForm index={index} data={data} setData={setData}  /> :<CountdownTile title={title} days={days} hours={hours} minutes={minutes} seconds={seconds} isNear={isNear}/>;
    }
};

export { Tile };
