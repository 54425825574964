import {React, useState, useEffect} from 'react';
import './App.css';
import { TileArea } from './tile/TileArea';
import { SignInButton } from './SignInButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useTileHook } from './tile/TileHook';
import { SaveData } from './Data/GetData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [id, setId] = useState('');
  const { data, setData, edit, FlipEdit, editText, isLoading } = useTileHook(id);
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0){
      setId(accounts[0].localAccountId);
    }
  }, [accounts]);

  const ProfileContent = () => {
    const firstName = accounts[0].name.split(' ')[0];
    return (
        <>
            <h1 className="card-title">{firstName}'s Dashboard</h1>
        </>
    );
  };

  const onSave = async () =>{
    try {
      await SaveData(id, data);
      // Notification('Data saved successfully!');
      toast.success('Data saved successfully!');
    } catch (error){
      console.error(error);
      // Notification('Could not save Data!');
      toast.error('Could not save Data!');
    }
  };
  
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent/>
        <button type="submit" onClick={FlipEdit} className='btn btn-primary' >{editText}</button>
        <button type="submit" onClick={onSave} className='btn btn-primary' >Save</button>
        {isLoading ? <h5 className="card-title">Loading...</h5>: <TileArea id={id} data={data} edit={edit} setData={setData}/>}
        <ToastContainer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5 className="card-title">Please sign-in to see your dashboard.</h5>
        <div className="collapse navbar-collapse justify-content-end">
          <SignInButton />
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
