const timeRemaining = (secs) => {
    const seconds = formatTime(secs % 60);
    const minutes = formatTime(Math.floor((secs / 60) % 60));
    const hours = formatTime(Math.floor((secs / 3600) % 24));
    const days = formatTime(Math.floor(secs / (3600 * 24)));
    return [ seconds, minutes, hours, days];
};

function formatTime(time) {
    return time < 10 && time >= 0 ? `0${time}` : time;
}

export {timeRemaining}