import React from 'react';

export const EmptyTile = () => {
    return (
        <div className='tile-empty'>
            <></>
        </div>
    );
};

export const ElapsedTile = ({ title }) => {
    return (
        <div className='tile'>
            <h1 id="Title">Time till {title}</h1>
                <div className="countdown-container-near">
                    <p className="big-text" id="days">Elapsed !!!</p>
                </div>
        </div>
    );
};

export const CountdownTile = ({title, days, hours, minutes, seconds, isNear}) => {
    return (
        <div className='tile'>
            <h1 id="Title">Time till {title}</h1>
            <div className={isNear ? "countdown-container-near": "countdown-container"}>
                <div className="countdown-el days-c">
                    <p className="big-text" id="days">{days}</p>
                    <span>days</span>
                </div>
                <div className="countdown-el hours-c">
                    <p className="big-text" id="hours">{hours}</p>
                    <span>hours</span>
                </div>
                <div className="countdown-el mins-c">
                    <p className="big-text" id="mins">{minutes}</p>
                    <span>mins</span>
                </div>
                <div className="countdown-el seconds-c">
                    <p className="big-text" id="seconds">{seconds}</p>
                    <span>seconds</span>
                </div>
            </div>
        </div>
    );
};
