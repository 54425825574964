const GetData = async (id) => {
    const gql = `
        query getTileById($id: ID!) {
            countdown_by_pk(id: $id) {
                id
                tiles {
                Title
                Date
                IsRecurring
                IsEmpty
                }
            }
        }`;

    const query = {
        query: gql,
        variables: {
            id: id,
        },
    };

    const endpoint = "/data-api/graphql";
    const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
    });
    const result = await response.json();
    return result.data.countdown_by_pk ? result.data.countdown_by_pk : {tiles: []};
};

const SaveData = async (id, data) => {
    const dbData = {id: id, tiles: data}
    const gql = `
        mutation update($id: ID!, $_partitionKeyValue: String!, $item: UpdateCountdownInput!) {
            updateCountdown(id: $id, _partitionKeyValue: $_partitionKeyValue, item: $item) {
                id
                tiles {
                Title
                Date
                IsRecurring
                IsEmpty
                }
            }
        }`;

    const query = {
        query: gql,
        variables: {
            id: id,
            _partitionKeyValue: id,
            item: dbData
        }
    };

    const endpoint = "/data-api/graphql";
    const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
    });
    const result = await response.json();
    return result.data;
};

const emptyTiles = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
        data.push({Title: '', Date: '', IsRecurring: false, IsEmpty: true});
    }
    return data;
}

const InsertData = async (id) => {
    const data = {
        id: id,
        tiles: emptyTiles()
      };
    const gql = `
        mutation create($item: CreateCountdownInput!) {
          createCountdown(item: $item) {
            id
            tiles {
            Title
            Date
            IsRecurring
            IsEmpty
            }
          }
        }`;
      
      const query = {
        query: gql,
        variables: {
            item: data
        } 
      };
      
      const endpoint = "/data-api/graphql";
      const result = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query)
      });
    
      const response = await result.json();
      return response.data.createCountdown;
};


export { GetData, SaveData, InsertData };