import { useState, useEffect} from 'react';
import { GetData } from '../Data/GetData';
import { toast } from 'react-toastify';

const useTileHook = (id) => {
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [editText, setEditText] = useState('Edit');
    const [isLoading, setIsLoading] = useState(false);
    
    const FlipEdit = () => {
      setEdit(!edit);
      setEditText(editText === 'Edit' ? 'Preview' : 'Edit');
    };

    useEffect(() => {
      (async function() {
        if (data.length === 0 && id !== '') {
          setIsLoading(true);
          try
          {
            const { tiles } = process.env.REACT_APP_USE_DATAFILE === 'true' ? require('../Data/CountdownData.json') : await GetData(id);
            setData(tiles); 
          } catch (error){
            console.error("Failed to load data! " + error.message);
            toast.error('Failed to load data!');
          }
          setIsLoading(false);
        }
      })();
      // eslint-disable-next-line
     }, [id]);

    return {data, setData, edit, setEdit, FlipEdit, editText, isLoading};
};

export { useTileHook };